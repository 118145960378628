<i18n src="@/locales/testimonial63.json" />
<template>
  <div class="fixed-background">
    <ImanTestimonialHeader :img="img">
      <h1>{{ $t('h1_title_1') }}</h1>
      <p>
        {{ $t('header_p1') }}
      </p>
    </ImanTestimonialHeader>
    <ImanTestimonialMessage type="is-info">
      <span v-html="$t('message_1')" />
    </ImanTestimonialMessage>
    <ImanTestimonialArticle :title="$t('h2_title_1')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial63/avant-apres-implant-dentaire-sans-os.jpg"
            alt="Remplacer les dents avec bridge cassé"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p1') }}
          </p>
        </div>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('h2_title_2')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial63/implant-dentaire-sans-os.jpg"
            alt="Pose d'implants dentaires immédiats"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p2_1') }}
          </p>
        </div>
      </div>
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial63/resultat-implant-basal.jpg"
            alt="implant dentaire immédiat avis"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p2_2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
        </div>
      </div>
    </ImanTestimonialArticle>
    <ImanTestimonialArticle :title="$t('h2_title_3')">
      <div class="columns is-vcentered">
        <div class="column is-3">
          <img
            src="@/assets/img/testimonial/testimonial63/temoignage-implant-dentaire-sans-os.jpg"
            alt="implant dentaire en 3 jours"
          >
        </div>
        <div class="column">
          <p>
            {{ $t('article_p3') }}
          </p>
        </div>
      </div>
    </ImanTestimonialArticle>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: "Testimonial63",
  mixins: [metaMixin],
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      video: {
        src: 'https://www.youtube.com/embed/MagohKcbvCA'
      },
      img: {
        path: 'testimonial/testimonial63/miniature-implant-sans-os.jpg',
        alt: ''
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/miniature_63.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/scss/variables';
.card {
  margin: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.70);
  .card-header-title {
    background-color: rgba(224, 32, 64, 0.70);
    font-family: 'Comfortaa', cursive;
    color: white;
  }
  .card-content {
    text-align: justify;
  }
}
</style>
